/***************************************************************************************
 * FileName      : MagazineDetail.js
 * Description   : 소곤핏 매거진 상세페이지
 * Company       : (주)엘리그
 * Author        : 박권희, 최현민
 * Created Date  : 2024.03.19
 * Modifide Date : 2024.07.10 : CHM : 매거진 API 호출 수정
 *               :
 * Reference     :
 ***************************************************************************************/
import { useState, useEffect } from "react";
import MobileHeader from "./Component/MobileHeader";
import { getMagazine } from "../../api/services/magazine";
import { useParams } from "react-router-dom";

import { Helmet } from "react-helmet-async";
import Gnb from "../../Gnb";
import MagazineContent from "./MagazineDetail/MagazineContent";
import { getRecommendMagazine } from "../../api/services/magazine";
import { useNavigate } from "react-router-dom/dist";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";

function MagazineDetail() {
	const { id } = useParams();

	const [magazineTitle, setMagazineTitle] = useState("소곤핏 매거진");
	const [magazineData, setMagazineData] = useState(""); // meta tag용 데이터
	const [recommendMagazine, setRecommendMagazine] = useState([]); // 추천 매거진

	useEffect(() => {
		getMagazine({ id: id })
			.then((res) => {
				setMagazineTitle(res?.title);
				setMagazineData(res);
			})
			.catch((err) => {
				// console.log(err);
			});
		getRecommendMagazine(id)
			.then((res) => {
				setRecommendMagazine(res?.data?.data);
				console.log("recommend : ", res?.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	return (
		<>
			<Helmet>
				<title>매거진 - {magazineTitle}</title>
				<meta name="Description" content={magazineTitle + "... 소곤핏 방문하고 더 많은 글 확인하기."} />
				<meta name="Date" content={magazineData?.updated_at} />{/* 검색엔진 SEO 업로드 시간 표시용 */}
				<meta property="og:site_name" content="소곤핏 매거진" />
				<meta property="og:title" content={magazineTitle} />{/* sns 공유용 타이틀 */}
				<meta property="og:image" content={magazineData?.magazineImg} />{/* sns 공유용 이미지 */}
			</Helmet>
			<MobileHeader back={true} title={magazineTitle} />
			<div className="magazine p-2">
				{/* 매거진내용 */}
				<MagazineContent id={id} magazineTitle={magazineTitle} />
				
				{/* 추천글 */}
				{recommendMagazine?.length > 0 ? (
					<div className="mb-5">
						<div className="my-magazine-title">
							소곤님을 위한 추천글
						</div>
						{recommendMagazine?.map((item) => (
							<MagazineBlock key={item?.id} item={item} />
						))}
					</div>
				) : (
					<div className="mb-5"></div>
				)}
			</div>

			<Gnb />
		</>
	);
}

function MagazineBlock({ item }) {
	const navigate = useNavigate();
	return (
		<>
			<div
				className="my-magazine-content"
				onClick={() => {
					window.hackleClient.track({
						key: "clicked_sogonlog",
						properties: {
							클릭위치: "소곤로그 추천글",
							타이틀: item?.title,
							글작성자: item?.user?.username,
							카테고리: item?.category?.name,
							레이아웃: "요약리스트형",
						},
					});
					navigate(`/magazine/${item?.id}`);
				}}
			>
				<div className="my-m-img">
					<img src={item?.main_image} alt={item?.title} />
				</div>

				<div className="magazine-info">
					<div className="post-tags">
						<span
							key={item?.category?.id}
							className="post-tag"
							style={{ padding: "4px 6px 4px 0px" }}
						>
							{item?.category?.name}
						</span>
					</div>
					<div className="post-tit">
						<p>{item?.title}</p>
						<div className="m-mheart">
							<FontAwesomeIcon
								icon={faHeart}
								style={{ marginRight: "3px" }}
							/>
							{item?.like_count}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default MagazineDetail;
