/***************************************************************************************
 * FileName      : Post.js
 * Description   : 소곤핏 소곤톡 리스트 페이지
 * Company       : (주)엘리그
 * Author        : 박권희
 * Created Date  : 2024.05.02
 * Modifide Date : 2024.08.23 : 디자인 수정사항 반영
 *               : 
 * Reference     :
 ***************************************************************************************/
import React, { useState, useEffect } from "react";
import MobileHeader from "./Component/MobileHeader";
import Gnb from "../../Gnb";
import Dropdown from "react-bootstrap/Dropdown";
import { BiSortAlt2 } from "react-icons/bi";
import { fetchPostData } from "../../api/services/post";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

import { useInfiniteQuery } from "react-query";

import Postitem from "./Post/Postitem";

import InfiniteScroll from "react-infinite-scroll-component";

import { getMe } from "../../api/common/memberApi";
import { Helmet } from "react-helmet-async";
import ReactGA from "react-ga4";
import "react-loading-skeleton/dist/skeleton.css";
import PostSkeleton from "./Post/PostSkeleton";

function Post() {
	const [page, setPage] = useState(0);
	const [tag, setTag] = useState("all");

	// 회원 정보
	const [user, setUser] = useState({});

	// 정렬
	const [order, setOrder] = useState("");

	useEffect(() => {
		getMe().then((data) => {
			const userData =
				data?.data === "guest"
					? { type: "guest", username: "" }
					: { type: data?.type, username: data?.username };

			setUser(userData);
		});
	}, []);

	const {
		data,
		fetchNextPage,
		hasNextPage,
		isFetching,
		isFetchingNextPage,
		isLoading,
	} = useInfiniteQuery(
		["postdata", tag, page, order],
		({ pageParam = 1 }) => fetchPostData(tag, pageParam, order),
		{
			getNextPageParam: (lastPage, allPages) => {
				return lastPage.nextPage;
			},
		}
	);


	const [activeIndex, setActiveIndex] = useState(null);

	useEffect(() => {
		setActiveIndex("-1");
	}, []);

	const handleSelect = (eventKey) => {
		setOrder(eventKey);
	};

	if (isLoading) {
		return (
			<PostSkeleton/>
		);
	}
	
	return (
		<>
			<Helmet>
				<title>소곤핏 Sogonfit - 소곤톡</title>
				<meta name="description" content="여자들의 말 못할 고민 해결, 일상 속 작은 휴식처" />
			</Helmet>
			<MobileHeader back={true} title={"소곤톡"} />
			<div className="post">
				<section className="prodcut-content my-5">

					<div className="inner">
						<div className="sort-box">
							<p>
								총 <span>{data?.pages[0]?.totalCnt}</span>건
							</p>
							{/* <p onClick={handleOrderingModalShow}>정렬</p> */}
							<Dropdown onSelect={handleSelect}>
								<Dropdown.Toggle id="sort-dropdown">
									<BiSortAlt2 />
									정렬
								</Dropdown.Toggle>

								<Dropdown.Menu>
									<Dropdown.Item eventKey="popular">
										인기순
									</Dropdown.Item>
									<Dropdown.Item eventKey="reply">
										댓글순
									</Dropdown.Item>
									<Dropdown.Item eventKey="new">
										최신순
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</div>

						<div className="post-wrap mb-5">
							<InfiniteScroll
								style={{
									display: "flex",
									flexWrap: "wrap",
									gap: "10px",
									paddingBottom: "50px",
								}}
								dataLength={
									data?.pages
									? data.pages.reduce(
										(total, page) => total + page.data.length,
										0
										)
									: 0
								}
								next={() => fetchNextPage()}
								hasMore={hasNextPage}
								loader={<div></div>}
								endMessage={<div></div>}
							>
								{data?.pages?.map((page, pageIndex) => (
									<React.Fragment key={pageIndex}>
										{page.data.map((item) => (
											<Postitem
												isLoading={isFetchingNextPage}
												item={item}
												key={item.id}
											/>
										))}
									</React.Fragment>
								))}
							</InfiniteScroll>
						</div>

					{user?.type !== "guest" && (
						<Link
							to="/post/write"
							className="posting-btn"
							onClick={() => {
								window.hackleClient.track({
									key: "clicked_create_sogonlog",
								});
								ReactGA.event({
									action: "소곤톡 포스팅",
									category: "소곤톡 글쓰기",
								});
							}}
						>
							<FontAwesomeIcon className="pen" icon={faPen} />
						</Link>
					)}
					</div>
				</section>

				<Gnb />
			</div>
		</>
	);
}

export default Post;
