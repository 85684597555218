/***************************************************************************************
 * FileName      : PostDetails.js
 * Description   : 소곤핏 소곤톡 상세 페이지
 * Company       : (주)엘리그
 * Author        : 박권희, 최현민
 * Created Date  : 2024.05.02
 * Modifide Date : 2024.06.17 : CHM : 프로필 클릭이벤트 추적 GA 적용
 *               : 2024.10.08 : CHM : 비회원 댓글 조회 허용
 *               : 2024.09.12 : PKH : 좋아요 아이콘 수정
 * Reference     :
 ***************************************************************************************/
import React from "react";
import { useState, useEffect } from "react";
import { AiOutlineAlert } from "react-icons/ai";
import { GoTrash } from "react-icons/go";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
// import MobileHeader from "./Component/MobileHeader";
import MobileHeader2 from "./Component/MobileHeader2";
import { deletePost } from "../../api/services/post";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { faHeart as faSolidHeart } from "@fortawesome/free-solid-svg-icons";

import { faPen } from "@fortawesome/free-solid-svg-icons";
import { useParams, Link } from "react-router-dom";

import { getPostDetails, adminHiddenPost } from "../../api/services/post";

import { registerReply } from "../../api/services/post";
import PostComment from "./PostDetails/PostComment";

import { fetchReplyList } from "../../api/services/post";
import { getMe } from "../../api/common/memberApi";

import ReportModal from "./PostDetails/ReportModal";
import { togglePostLike } from "../../api/services/post";

import { Helmet } from "react-helmet-async";
import Cookies from "js-cookie";

import { Modal } from "react-bootstrap";
import { MdMoreVert } from "react-icons/md";
import Dropdown from "react-bootstrap/Dropdown";
import ReactGA from "react-ga4";
import { ReactComponent as HeartIcon } from "../../assets/icon/heart.svg";

function PostDetails() {
	const navigate = useNavigate();

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		watch,
	} = useForm();

	const queryClient = useQueryClient();

	const { postId } = useParams();

	// 소곤톡 댓글 조회
	const { data: replyData, refetch } = useQuery(["replyList", postId], () =>
		fetchReplyList(postId)
	);

	// 소곤톡 상세 데이터 조회
	const { data, isLoading } = useQuery(["post", postId], () => {
		return getPostDetails({ id: postId, viewCountKey: Cookies.get("_ga") });
	});
	

	const [more, setMore] = useState(false);

	// 회원 정보
	const [user, setUser] = useState({});

	// 해당 글 좋아요
	const [isWished, setIsWished] = useState("");

	// 해당 글 글쓴이
	const [writer, setWriter] = useState("");

	// 해당 글 글쓴이와 로그인한 유저가 같은지 확인
	const [isWriter, setIsWriter] = useState("");

	useEffect(() => {
		if (data && data.user && data.user.username) {
			setWriter(data?.user?.username);
		}

		if (data && data.is_liked) {
			setIsWished(data?.is_liked);
		}

		if (data && data.is_written) {
			setIsWriter(data?.is_written);
		}
		

	}, [data]);


	// 신고 모달 상태
	const [showModal, setShowModal] = useState(false);

	const handleCloseModal = () => setShowModal(false);

	const handleShowModal = () => {
		if (user?.type === "guest") {
			const userConfirmed = window.confirm(
				"로그인이 필요한 기능입니다. 로그인 페이지로 이동할까요?"
			);
			if (userConfirmed) {
				navigate("/login");
			}
		} else {
			setShowModal(true);
		}
	};

	// 삭제
	const handleDeleteClick = (postId) => {
		const confirmDelete = window.confirm("정말로 삭제 하시겠습니까?");
		if (confirmDelete) {
			deletePost(postId)
				.then((response) => {
					alert("해당 게시글이 삭제되었습니다.");
					window.location.href = "/post";
				})
				.catch((error) => {
					alert(error.response.data.error);
				})
				.finally(() => {});
		}
	};

	// 관리자 권한 해당 글 비공개 처리
	const handleAdminHiddenPost = (postId) => {
		const confirmDelete = window.confirm("정말로 비공개 하시겠습니까?");
		if (confirmDelete) {
			adminHiddenPost(postId)
				.then((response) => {
					alert("해당 게시글이 비공개 되었습니다.");
					window.location.href = "/post";
				})
				.catch((error) => {
					alert(error.response.data.error);
				})
				.finally(() => {});
		}
	};

	useEffect(() => {
		getMe().then((data) => {
			const userData =
				data?.data === "guest"
					? { type: "guest", username: "" }
					: { type: data?.type, username: data?.username };

			setUser(userData);
		});
	}, []);

	// 댓글 등록
	const createPostReplyMutaion = useMutation(registerReply, {
		onSuccess: (data) => {
			reset();
			queryClient.invalidateQueries(["replyList", postId]);
		},
		onError: (data) => {
			data.response.data.error.map((errorMessage) => {
				alert(errorMessage);
			});
		},
	});

	const onSubmit = async (formData) => {
		formData.post = postId;
		await createPostReplyMutaion.mutateAsync(formData);
	};

	const createLikeMutaion = useMutation(togglePostLike, {
		onSuccess: (data) => {
			// console.log("좋아요성공",data)
			queryClient.invalidateQueries(["post", postId]);
		},
		onError: (data) => {
			data?.response?.status === 401
				? alert("로그인이 필요한 기능입니다.")
				: alert(data?.request.responseText);
		},
	});

	const toggleWish = () => {
		if (user.type === "guest") {
			const isConfirmed = window.confirm(
				"로그인이 필요한 서비스입니다. 로그인 페이지로 이동하시겠습니까?"
			);
			if (isConfirmed) {
				window.location.href = "/login";
			}
		} else {
			setIsWished(!isWished);

			const data = {
				post: postId,
			};

			createLikeMutaion.mutate(data);
		}
	};

	// 줄바꿈 문자를 <br /> 태그로 변환하는 함수
	const formatContent = (text) => {
		return (text || "").split("\n").map((line, index) => (
			<React.Fragment key={index}>
				{line}
				<br />
			</React.Fragment>
		));
	};
	// 이미지 모달
	const [selectedImg, setSelectedImg] = useState(null);
	const [showImgModal, setShowImgModal] = useState(false);

	const handleImgClick = (img) => {
		setSelectedImg(img);
		setShowImgModal(true);
	};

	const handleCloseImgModal = () => {
		setShowImgModal(false);
		setSelectedImg(null);
	};

	return (
		<>
			<Helmet>
				<title>{writer}님의 소곤소곤</title>
				<meta name="Description" content={data?.content} />
				<meta name="Date" content={data?.posted_at} />{/* 검색엔진 SEO 업로드 시간 표시용 */}
				<meta property="og:site_name" content="소곤핏 소곤톡" />
				<meta property="og:title" content={"소곤핏 - "+data?.title} />
				<meta
					property="og:image"
					content="/img/sogonfit_thumbnail.jpeg"
				/>
			</Helmet>
			<MobileHeader2
				back={true}
				username={data?.user?.nickname || data?.user?.username}
				more={more}
				setMore={setMore}
			/>
			<div className="post">
				<section className="prodcut-content mt-5">
					<div className="inner">
						<div className="post-details-info">
							{/* 소곤톡 태그 */}
							<div className="post-tags">
								{data?.tags.map((tag) => (
									<span
										key={tag?.id}
										className="post-tag"
										onClick={() => {
											ReactGA.event({
												action: "태그 클릭",
												category: `[소곤톡] 상세페이지 태그 클릭 : ${tag?.name}`,
											});
										}}
									>
										#{tag?.name}
									</span>
								))}
							</div>
							<div className="post-user-wrap">
								<div className="post-user">
									{data?.user?.profile ? (
										<img
											src={data.user.profile}
											alt="User Profile"
											onClick={() => {
												ReactGA.event({
													action: "유저 프로필 클릭",
													category:
														"[소곤톡] 작성자 프로필 사진 클릭",
												});
											}}
											onContextMenu={(e) =>
												e.preventDefault()
											}
										/>
									) : (
										<img
											src={
												process.env.PUBLIC_URL +
												"/favicon-96x96.png"
											}
											alt="Default Profile"
											onClick={() => {
												ReactGA.event({
													action: "유저 프로필 클릭",
													category:
														"[소곤톡] 작성자 프로필 사진 클릭",
												});
											}}
											onContextMenu={(e) =>
												e.preventDefault()
											}
										/>
									)}
									<div className="">
										<span
											onClick={() => {
												ReactGA.event({
													action: "유저 프로필 클릭",
													category:
														"[소곤톡] 작성자 닉네임 클릭",
												});
											}}
										>
											{data?.user?.username == "Anonymous"
												? "익명의 소곤님"
												: data?.user?.nickname}
										</span>
										<div className="post-date">
											<span className="date">{data?.posted_at}</span>
											<span>조회 {data?.view_count}</span>
										</div>
									</div>
								</div>
								<div>
									{user?.type === "A" ? (
										<>
											<Dropdown>
												<Dropdown.Toggle id="sort-dropdown">
													<MdMoreVert />
												</Dropdown.Toggle>

												<Dropdown.Menu>
													<Dropdown.Item
														onClick={() =>
															handleAdminHiddenPost(
																postId
															)
														}
													>
														비공개
													</Dropdown.Item>
													{isWriter && (
														<Dropdown.Item
															onClick={() =>
																handleDeleteClick(
																	postId
																)
															}
														>
															삭제
														</Dropdown.Item>
													)}
												</Dropdown.Menu>
											</Dropdown>
										</>
									) : isWriter ? (
										// 본인 글 삭제 버튼
										<button
											className="more-button"
											onClick={() =>
												handleDeleteClick(postId)
											}
										>
											<GoTrash
												style={{
													color: "#bfbfbf",
													fontSize: "20px",
												}}
											/>
										</button>
									) : (
										// 타인 글 신고 버튼
										<button
											className="more-button"
											onClick={handleShowModal}
										>
											<AiOutlineAlert
												style={{
													color: "#bfbfbf",
													fontSize: "20px",
												}}
											/>
										</button>
									)}
								</div>
							</div>
							
						</div>
						<div className="post-content">
							<p className="post-title">{data?.title}</p>
						
							<span>{formatContent(data?.content)}</span>
							{/* 콘텐츠 내용 */}
							{/* <span>{data?.content}</span> */}

							{/* 이미지영역 */}
							{data?.images?.length > 0 && (
								<PostImg
									imgList={data?.images}
									onImgClick={handleImgClick}
								/>
							)}

							<div style={{ textAlign: "center" }}>
								<button
									className={`likeBtn mt-3 ${isWished ? 'wished' : ''}`}
									onClick={toggleWish}
								>
									<HeartIcon 
										className={`like-icon ${isWished ? 'liked' : ''}`}
										/>
								
									{data?.like_count}
								</button>
							</div>
						</div>

						{/*
						{user?.type === "guest" && data?.reply_count > 0 && (
							<div
								className="my-2"
								onClick={() => navigate("/login")}
							>
								{[...Array(data?.reply_count)].map(
									(_, index) => (
										<img
											key={index}
											src={
												process.env.PUBLIC_URL +
												"/img/guest_reply.png"
											}
											alt=""
											width="100%"
										/>
									)
								)}
							</div>
						)}
						*/}

						{replyData?.data?.map((comment) => (
							<PostComment
								key={comment?.id}
								comment={comment}
								user={user}
								postId={postId}
							/>
						))}
					</div>

					<div
						className="offcanvas offcanvas-bottom"
						tabIndex="-1"
						id="offcanvasBottom"
						aria-labelledby="offcanvasBottomLabel"
					>
						<div className="offcanvas-body small">
							<div
								className="more-button"
								onClick={handleShowModal}
							>
								<AiOutlineAlert style={{ fontSize: "20px" }} />
								<span>신고하기</span>
							</div>
							<div
								className="more-button"
								onClick={() => handleDeleteClick(postId)}
							>
								<GoTrash style={{ fontSize: "18px" }} />
								<span>삭제하기</span>
							</div>
						</div>
					</div>
				</section>

				<div className="reply-wrap">
					<form onSubmit={handleSubmit(onSubmit)}>
						{user?.type === "guest" ? (
							<>
								<input
									type="text"
									className=""
									placeholder="로그인 후 이용 가능합니다"
									{...register("content")}
									disabled
								/>
							</>
						) : (
							<>
								<input
									type="text"
									className=""
									placeholder="댓글을 입력해주세요."
									{...register("content")}
								/>
								<div className="right-box">
									<input
										className="checkboxA"
										type="checkbox"
										id="hiddenCheck"
										{...register("is_anonymous")}
									/>

									<label
										className="form-check-label mr-1"
										for="hiddenCheck"
									>
										익명
									</label>

									<button className="" type="submit">
										등록
									</button>
								</div>
							</>
						)}
					</form>
				</div>
			</div>

			{/* 신고  모달 */}
			<ReportModal
				show={showModal}
				onHide={handleCloseModal}
				postId={postId}
				reportType={"post"}
			/>

			{/* 이미지  모달 */}
			{showImgModal && (
				<ImgModal
					img={selectedImg}
					show={showImgModal}
					onHide={handleCloseImgModal}
				/>
			)}
		</>
	);
}

const PostImg = ({ imgList, onImgClick }) => {
	return (
		<div
			className="none-scroll"
			style={{ overflowX: "scroll", marginTop: "24px" }}
		>
			<div
				style={{
					display: "inline-flex",
					gap: "14px",
					margin: "12px 2px",
				}}
			>
				{imgList.map((img, index) => (
					<div
						key={index}
						className="post-image-block"
						onClick={() => onImgClick(img)}
					>
						<img
							src={img}
							className="post-image"
							onContextMenu={(e) => e.preventDefault()}
						/>
					</div>
				))}
			</div>
		</div>
	);
};

const ImgModal = ({ img, show, onHide }) => {
	// 마우스 오른쪽 클릭 막기
	const handleContextMenu = (e) => {
		e.preventDefault();
	};
	return (
		<Modal show={show} onHide={onHide} centered>
			<Modal.Header closeButton />
			<Modal.Body>
				{img && (
					<img
						src={img}
						alt="Selected"
						className="w-100"
						onContextMenu={handleContextMenu}
					/>
				)}
			</Modal.Body>
		</Modal>
	);
};

export default PostDetails;
